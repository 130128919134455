<template>
	<div>
		<v-row>
			<v-col class="tw-mb-2">
				<div
					class="tw-text-base tw-font-figtree tw-text-charcoal tw-text-left tw-font-semibold tw-uppercase"
				>
					{{ title }}
				</div>
			</v-col>
		</v-row>
		<v-row v-if="trips.length === 0">
			<v-col cols="12">
				<p class="tw-text-light-grey">
					{{
						type === "past"
							? "You haven’t been on a trip yet! Get the crew together and start planning."
							: "Nothing to see here"
					}}
				</p>
			</v-col>
		</v-row>
		<v-row
			v-for="trip in trips"
			:key="trip.id"
			class="tw-mb-4 tw-mx-0"
			@click="goToTrip(trip)"
			ripple
			v-else
		>
			<v-col
				class="tw-rounded-lg tw-py-3 tw-px-5 tw-cursor-pointer tw-bg-lighter-grey tw-border tw-border-solid tw-border-lightest-grey tw-shadow-custom-sm"
				:class="{ 'j-premium': trip.isPremium }"
				cols="12"
			>
				<v-row align="center">
					<v-col cols="3">
						<v-img :src="iconUrl(trip)" />
					</v-col>
					<v-col cols="7" class="text-left">
						<h4
							class="tw-font-semibold tw-truncate"
							:class="{
								'tw-text-dark-green-two': !trip.isPremium,
								'tw-text-white': trip.isPremium
							}"
						>
							{{ trip.title }}
						</h4>
						<p
							class="tw-m-0 tw-text-xs"
							:class="{
								'tw-text-dark-green-two': !trip.isPremium,
								'tw-text-white': trip.isPremium
							}"
						>
							Date: {{ getFormattedDateRange(trip.startDate, trip.endDate) }}
						</p>
					</v-col>
					<v-col cols="2">
						<v-icon class="tw-text-grey-one">mdi-arrow-right</v-icon>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import { DESIGNS } from "@/enums/designs.js";
export default {
	name: "TripComponent",
	mixins: [FormattedDateRange],
	props: ["title", "trips", "type"],
	data() {
		return {
			designs: DESIGNS
		};
	},
	methods: {
		iconUrl(trip) {
			let invitation = trip.trip_invitation;
			if (
				!trip.isPremium ||
				!invitation ||
				invitation.template.toLowerCase() === this.designs.DEFAULT
			) {
				return require(`@/assets/svgs/my_trips/${trip.icon}`);
			} else if (
				invitation.template.toLowerCase() === this.designs.SIMPLE ||
				invitation.template.toLowerCase() === this.designs.SADDLE ||
				invitation.template.toLowerCase() === this.designs.GREEN_PALM
			) {
				return require(
					`@/assets/svgs/custom-trip-icons/${invitation.template.toLowerCase()}.png`
				);
			} else {
				return require(
					`@/assets/svgs/custom-trip-icons/${invitation.template.toLowerCase()}.svg`
				);
			}
		},
		async goToTrip(trip) {
			try {
				const res = await this.$store.dispatch("trip/get", [
					trip.id,
					{
						query: {
							include: "users,trip_invitation"
						}
					}
				]);
				if (res.active && res.users) {
					let member = res.users.filter(
						(user) => user.id === this.$store.state.auth.user.id
					)[0];
					let rsvp = member.trip_invite.status;
					if (rsvp === "declined") {
						this.$router.push({
							name: "CrewEditRoute",
							params: {
								id: trip.id,
								userId: member.id
							}
						});
					} else {
						this.goTo(trip);
					}
				} else {
					this.goTo(trip);
				}
			} catch {
				this.goTo(trip);
			}
		},
		goTo(trip) {
			this.$store.commit("meta/setCurrentTrip", trip);
			this.$router.push({ name: "TripViewRoute", params: { id: trip.id } });
		}
	}
};
</script>
