var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"tw-mb-2"},[_c('div',{staticClass:"tw-text-base tw-font-figtree tw-text-charcoal tw-text-left tw-font-semibold tw-uppercase"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])],1),(_vm.trips.length === 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"tw-text-light-grey"},[_vm._v(" "+_vm._s(_vm.type === "past" ? "You haven’t been on a trip yet! Get the crew together and start planning." : "Nothing to see here")+" ")])])],1):_vm._l((_vm.trips),function(trip){return _c('v-row',{key:trip.id,staticClass:"tw-mb-4 tw-mx-0",attrs:{"ripple":""},on:{"click":function($event){return _vm.goToTrip(trip)}}},[_c('v-col',{staticClass:"tw-rounded-lg tw-py-3 tw-px-5 tw-cursor-pointer tw-bg-lighter-grey tw-border tw-border-solid tw-border-lightest-grey tw-shadow-custom-sm",class:{ 'j-premium': trip.isPremium },attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-img',{attrs:{"src":_vm.iconUrl(trip)}})],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"7"}},[_c('h4',{staticClass:"tw-font-semibold tw-truncate",class:{
							'tw-text-dark-green-two': !trip.isPremium,
							'tw-text-white': trip.isPremium
						}},[_vm._v(" "+_vm._s(trip.title)+" ")]),_c('p',{staticClass:"tw-m-0 tw-text-xs",class:{
							'tw-text-dark-green-two': !trip.isPremium,
							'tw-text-white': trip.isPremium
						}},[_vm._v(" Date: "+_vm._s(_vm.getFormattedDateRange(trip.startDate, trip.endDate))+" ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{staticClass:"tw-text-grey-one"},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }