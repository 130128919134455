export const DESIGNS = {
  DEFAULT: "default",
  SADDLE: "saddle",
  BIRTHDAY: "birthday",
  COUNTRY: "country",
  SIMPLE: "simple",
  PURPLE_COW: "purple-cow",
  GREEN_PALM: "green-palm",
  BLUE_PALM: "blue-palm",
  PINK_MIMOSA: "pink-mimosa",
  RIP: "rip"
};
