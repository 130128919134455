<template>
	<div>
		<TripsComponent
			:trips="pastTrips"
			:title="'Past Trips'"
			:type="'past'"
			v-if="iconsLoaded"
		/>
	</div>
</template>
<script>
import { DateTime } from "luxon";
import feathersClient from "../feathers-client.js";
import TripsComponent from "@/components/trip/TripsComponent.vue";
export default {
	name: "TripsPast",
	components: {
		TripsComponent
	},
	data() {
		return {
			trips: [],
			iconsLoaded: false
		};
	},
	computed: {
		pastTrips() {
			return this.trips.filter(this.isPastTrip).sort(this.sortByDate);
		}
	},
	methods: {
		isPastTrip(trip) {
			const currentDate = DateTime.now().toISODate();
			const rsvpStatus = trip.trip_invite.status;
			return (
				trip.endDate && trip.endDate < currentDate && rsvpStatus === "accepted"
			);
		},
		sortByDate(a, b) {
			// Sort by TBD dates first, then by chronological order
			if (!a.startDate) return -1;
			if (!b.startDate) return 1;
			return new Date(b.startDate) - new Date(a.startDate);
		},
		async fetchAndLogUserTrips() {
			try {
				const userId = this.$store.state.auth.user.id;

				const response = await feathersClient.service("users").get(userId, {
					query: {
						include: `trip_invite,trip`
					}
				});

				this.trips = response.trips;

				const tripPromises = response.trips.map((trip) =>
					this.$store.dispatch("trip/get", [
						trip.id,
						{
							query: {
								include: "trip_invitation"
							}
						}
					])
				);

				const tripsDetailed = await Promise.all(tripPromises);

				for (const t of tripsDetailed) {
					for (const trip of this.trips) {
						if (t.id === trip.id) {
							trip.trip_invitation = t.trip_invitation;
						}
					}
				}
				this.iconsLoaded = true;
			} catch (error) {
				console.error("Error fetching user trips:", error);
			}
		}
	},

	beforeMount() {
		this.$store.commit("meta/setHeader", {
			defaultHeaderDisabled: false,
			pageTitle: "My Trips",
			bgColor: "#E6FFA1",
			fontColor: "#203848",
			tagline: null,
			showBackButton: true
		});
		this.fetchAndLogUserTrips();
	}
};
</script>
